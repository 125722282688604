/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const GET_CURRENCIES_DATE = gql`
  query GetCurrencies {
    currencies @client
    lastUpdate @client
  }
`;

export const GET_CURRENCIES = gql`
  query GetCurrencies {
    currencies @client
  }
`;

export const GET_NEWS = gql`
  query GetNews {
    news @client
  }
`;

export const GET_ADS_SETTINGS = gql`
  query GetAds {
    adsSettings @client
  }
`;
