import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import {
  Container,
  Details,
  Title,
  Desc,
  DownloadBox,
  DownloadLink,
} from './styles';

const DownloadApp = () => (
  <Container>
    <StaticImage
      src="./app-show.png"
      alt="Download application"
      quality={95}
      width={555}
    />

    <Details>
      <StaticImage
        src="./logo-rounded.png"
        alt="Logo"
        quality={95}
        width={100}
      />
      <Title>حمل تطبيق أسعار الصرف السورية الآن</Title>
      <Desc>
        ليصلك آخر أسعار صرف العملات في جميع المحافظات
        <br />
        بالإضافة لأسعار الذهب والبورصة وآخر الأخبار
      </Desc>

      <DownloadBox>
        <DownloadLink href="https://apps.apple.com/jo/app/syrian-exchange-prices/id1560563149">
          <StaticImage
            src="./app-store-download.png"
            alt="App store download"
            quality={95}
            width={160}
          />
        </DownloadLink>
        <DownloadLink href="https://play.google.com/store/apps/details?id=air.com.syriastocks.SyrianExchangePrices">
          <StaticImage
            src="./play-store-download.png"
            alt="Play store download"
            quality={95}
            width={160}
          />
        </DownloadLink>
      </DownloadBox>
    </Details>
  </Container>
);

export default DownloadApp;
