import styled from 'styled-components';
import downloadBG from '@images/download-bg.png';

export const Container = styled.div`
  background: url(${downloadBG}) no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  padding: 10px;
  display:flex;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 992px) {
    flex-direction: row;
    padding: 10px 0;
  }
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.textColor};
  max-width: 550px;
`;

export const Title = styled.h1`
  margin: 12px 0;
  font-family: ${props => props.theme.fonts.arabicFont};
  font-size: 16px;
`;

export const Desc = styled.p`
  font-family: ${props => props.theme.fonts.arabicFont};
  font-size: 16px;
  font-weight: 600;
  text-align: center;
`;

export const DownloadBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 24px;
`;

export const DownloadLink = styled.a.attrs({
  target: '_blank'
})`
  transition: all 0.5s ease;
  :hover {
    transform: translateY(-4px);
  }
`;